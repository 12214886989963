import React, { useContext, useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgpu";
import * as poseDetection from "@tensorflow-models/pose-detection";
import { Context } from "../MyContext";

interface PoseEstimationProps {
    video: HTMLVideoElement | null;
    videoSize: VideoSize
    setVideoSize: Function
    setLoaded: Function
}
export interface VideoSize {
    width: number;
    height: number;
}
export interface PoseSample {
    pose: poseDetection.Pose;
    time: number;
}


export const PoseEstimation: React.FC<PoseEstimationProps> = ({ video, videoSize, setVideoSize, setLoaded }) => {
    tf.setBackend("webgl");
    const [poses, setLocalPoses] = useState<null | poseDetection.Pose[]>(null);
    const { setPoses: setGlobalPoses } = useContext(Context);
    const estimationConfig = {
        maxPoses: 1,
        flipHorizontal: false,
    };

    const detectorConfig = {
        modelType: poseDetection.movenet.modelType.SINGLEPOSE_LIGHTNING,
    };
    useEffect(() => {
        let isMounted = true;
        let isSetup = false;
        const setupPoseDetection = async () => {
            if (isSetup) return;
            isSetup = true;
            console.log("pose detection setup")

            await tf.ready();
            const detector = await poseDetection.createDetector(
                poseDetection.SupportedModels.MoveNet,
                detectorConfig
            );
            let lastSamples = [] as PoseSample[];
            let hasLogged = false;

            const estimatePoses = async () => {
                if (isMounted && video && video.readyState >= 2 && video.videoWidth > 0 && video.videoHeight > 0) {
                    setLoaded(true);
                    try {
                        const poses = await detector.estimatePoses(video, estimationConfig);
                        const size: VideoSize = { width: video.videoWidth, height: video.videoHeight };
                        if (!videoSize || videoSize.width !== size.width || videoSize.height !== size.height) {
                            setVideoSize(size);
                            //console.log("input", video.videoWidth, video.videoHeight)
                        }
                        if (poses && poses.length > 0) {

                            //console.log("pose detected", poses)
                            setLocalPoses(poses);
                            setGlobalPoses(poses);
                            lastSamples.push({ pose: poses[0], time: Date.now() })
                            if (lastSamples.length > 10) {
                                lastSamples.shift();
                            }
                          if (lastSamples.length ){
                            
                            // log fps 
                            const fps = 1000 / (lastSamples[lastSamples.length - 1].time - lastSamples[0].time) * lastSamples.length;
                            console.log("fps", fps)
        
                          }
                          // calculate angle of arms 


                        }

                    } catch (error) {
                        console.error('Error in pose estimation:', error);
                    }
                }
                requestAnimationFrame(estimatePoses);
            };
            estimatePoses();
            //requestAnimationFrame(estimatePoses);

        };
        setupPoseDetection();

        return () => {
            isMounted = false;
        };
    }, [video]);

    return null;
};

export default PoseEstimation;
